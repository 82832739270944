var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"l-gutters"},[_c('div',{staticClass:"l-container"},[_c('h1',{staticClass:"mb-5 mt-11"},[_vm._v("Learn Room")]),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search in articles","clearable":"","outlined":"","hide-details":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.onSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)},"click:clear":_vm.onSearchClear},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1)],1),(_vm.filterItems && _vm.filterItems.length)?_c('div',{staticClass:"filters mb-15"},_vm._l((_vm.filterItems),function(filter){return _c('router-link',{key:filter.id,class:_vm.isFilterActive(filter),attrs:{"to":{
          name: 'helpAndFaqs.category',
          params: { articleId: filter.id ? filter.id : '' },
        }}},[_vm._v(" "+_vm._s(filter.title)+" ")])}),1):_vm._e(),_c('v-row',_vm._l((_vm.filteredArticles),function(article){return _c('v-col',{key:article.id,attrs:{"cols":"","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transition-swing",attrs:{"to":{
                name: 'helpAndFaqs.articleDetail',
                params: { id: article.id },
              },"elevation":hover ? 6 : 2}},[_c('v-img',{staticClass:"align-end",attrs:{"src":_vm.cloudinaryImageResize(article.blobUrl, 300),"height":"300","gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"}},[_c('v-card-title',{staticClass:"white--text",staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s(article.description)+" ")])],1),_c('v-card-actions',[_c('v-card-text',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" Read more ")]),_c('v-spacer'),_c('v-card-text',{staticClass:"text-right"},[_vm._v(" "+_vm._s(`${article.readTime} ${ article.readTime === 1 ? 'min' : 'mins' }`)+" ")])],1)],1)]}}],null,true)})],1)}),1),_c('v-row',[_c('v-col',[(_vm.showPagination)?_c('div',{staticClass:"text-center my-6"},[_c('v-pagination',{attrs:{"length":_vm.paginationLength},on:{"input":_vm.nextPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }