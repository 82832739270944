
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Route } from 'vue-router';
import { HelpAndFAQsModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { Article } from '@/api/contracts/helpAndFAQs/article';
import { ArticleCategory } from '@/api/contracts/helpAndFAQs/articleCategory';
import { SidebarMixin } from '@/mixins/sidebarMixin';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

const helpAndFAQsModule = getModule(HelpAndFAQsModule);

@Component({
  mixins: [SidebarMixin],
  methods: {
    cloudinaryImageResize,
  },
})
export default class HelpAndFAQsIndex extends LoggedInComponentBase {
  public articleCategories: ArticleCategory[] = [];
  public articlesPerPage: number = 9;
  public currentPage: number = 1;
  public searchKeyword: string = '';

  public get currentFilter(): ArticleCategory | undefined {
    return this.articleCategories.find(
      (category) => category.id === helpAndFAQsModule.currentArticleFilterId
    );
  }

  // article filtering logic
  public filterByTag(article: Article) {
    if (!this.currentFilter) return true; // return all with no filters

    return article.categories.find(
      (category) => category.id === this.currentFilter?.id
    );
  }

  public get filteredArticles(): Article[] {
    return this.helpArticles.filter(this.filterByTag);
  }

  public async getArticlesByKeyword(kw: string = '') {
    await helpAndFAQsModule.getArticles({
      pagination: {
        query: kw,
        limit: 9,
        start: 0,
      },
    });
  }

  public onSearch() {
    this.getArticlesByKeyword(this.searchKeyword);
  }
  public onSearchClear() {
    this.getArticlesByKeyword();
  }

  public get filterItems(): Array<ArticleCategory> {
    const filters: Array<ArticleCategory> = [];

    filters.push({
      id: 'all',
      title: 'All',
      description: '',
    });

    return [...filters, ...this.articleCategories];
  }

  public get helpArticles(): Article[] {
    return helpAndFAQsModule.articles;
  }

  public get helpArticlesPagination() {
    return helpAndFAQsModule.articlesPagination;
  }

  public get paginationLength(): number | null {
    return Math.ceil(this.helpArticlesPagination.total / this.articlesPerPage);
  }

  public get showPagination() {
    return this.helpArticlesPagination.total > this.articlesPerPage;
  }

  public get currentBrand() {
    return this.storesModule.currentBrand;
  }

  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: HelpAndFAQsIndex) => void)) => void
  ): Promise<void> {
    try {
      await helpAndFAQsModule.getArticles({
        category: to.params.articleId,
        pagination: {
          limit: 9,
          start: 0,
        },
      });

      await helpAndFAQsModule.getArticleCategories();
      helpAndFAQsModule.setCurrentArticleFilterId(to.params.articleId);
      next((vm) => (vm.currentPage = 1));
    } catch {
      next(false);
    }
  }

  public async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: HelpAndFAQsIndex) => void)) => void
  ): Promise<void> {
    this.currentPage = 1;
    try {
      helpAndFAQsModule.filterArticles({
        category: to.params.articleId,
        pagination: {
          limit: this.articlesPerPage,
          start: 0,
        },
      });
      next();
    } catch {
      next(false);
    }
  }

  created() {
    this.articleCategories = this.setArticleCategories();
  }

  public isFilterActive(filter: ArticleCategory) {
    return {
      active:
        filter.id === this.currentFilter?.id ||
        (filter.id === 'all' && !this.currentFilter),
    };
  }

  async nextPage(page: number) {
    await helpAndFAQsModule.getArticles({
      category: this.currentFilter?.id || 'all',
      pagination: {
        limit: this.articlesPerPage,
        start: this.articlesPerPage * (page - 1),
      },
    });
  }

  public setArticleCategories(): Array<ArticleCategory> {
    return helpAndFAQsModule.fullArticleCategories;
  }
}
